export enum AppRoute {
  Login = 'login',
  Users = 'users',
  Forbidden = 'forbidden',
  Home = 'home',
  Admin = 'admin',
  Imprint = 'imprint',
  TermsOfUse = 'terms-of-use',
  PrivacyPolicy = 'privacy-policy',
  Register = 'register',
  Help = 'help',
  Support = 'support',
  Partners = 'partners',
  Settings = 'settings',
  Orders = 'orders',
  FAQs = 'faqs'
}
