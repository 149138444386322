import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { ButtonsCancelComponent } from './buttons-cancel.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    MatRippleModule
  ],
  declarations: [ButtonsCancelComponent],
  exports: [ButtonsCancelComponent]
})
export class ButtonsCancelModule { }
