import { Component, Input } from '@angular/core';

import { BaseActionButton } from '../shared/base-button';

@Component({
  selector: 'kb-buttons-cancel',
  templateUrl: './buttons-cancel.component.html',
  styleUrls: ['./buttons-cancel.component.scss']
})
export class ButtonsCancelComponent extends BaseActionButton {
  @Input() disabled: boolean = false;
}