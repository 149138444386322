import { Guid } from '@app/shared/functions/guid';

export class BaseModel {
  id: string = Guid.Empty;

  created: string;

  createdBy: string;

  createdByName: string;

  modified: string;

  modifiedBy: string;
}
